<template>
  <div class="page-login-desktop">
    <v-card flat>
      <v-card-title>
        <PageTitle class="w-100" title="افزود مشترک" icon="mdi-pencil">
          <v-btn @click="$router.back()" color="primary" text elevation="0">
            بازگشت
            <v-icon class="mr-2">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </PageTitle>
      </v-card-title>


      <v-card-text>
        <v-row>
          <v-col :cols="12" :lg="6">
            <v-row>
              <v-col cols="12">
                <v-text-field label="شماره موبایل کاربر" hide-details outlined v-model="subscription.Managermobile"/>
              </v-col>

              <v-col cols="12">
                <v-text-field label="مدت زمان (روز)" type="number" hide-details outlined
                              v-model="subscription.Duration"/>
              </v-col>

              <v-col cols="12">
                <v-select label="انتخاب اشتراک" hide-details outlined v-model="subscription.Subscriptionname"
                          :items="prices"/>
              </v-col>

              <v-col cols="12">
                <v-combobox
                    multiple
                    deletable-chips
                    small-chips
                    label="انتخاب محله"
                    hide-details
                    outlined
                    v-model="subscription.Namesofneighborhoods"
                    :items="locations"
                    item-value="id"
                    item-text="name"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="12" :lg="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="شماره موبایل پرداخت کننده"
                    hide-details
                    outlined
                    v-model="subscription.Customermobile"/>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="مبلغ"
                    hide-details
                    outlined
                    v-model="subscription.price"/>
              </v-col>

              <v-col cols="12">
                <v-select
                    outlined
                    label="نوع پرداخت"
                    hide-details
                    v-model="subscription.paymenttype"
                    :items="paymentTypes"
                    item-value="id"
                    item-text="name"
                />
              </v-col>

              <v-col>
                <v-textarea
                    label="توضیحات"
                    outlined
                    v-model="subscription.description"
                />
              </v-col>


            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            elevation="0"
            color="primary" :loading="subscription.Loading" @click="store" large>
          تایید
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {fetchItems, store} from "@Newfiling/module-subscription/src/api";
import PageTitle from "@/components/PageTitle"
import {AppConfig} from "@Newfiling/Services"
import Vue from "vue";
import {removeCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopLogin',

  components: {
    PageTitle
  },

  data() {
    return {
      subscription: {
        Loading: false,
        Duration: null,
        Managermobile: "",
        Namesofneighborhoods: null,
        Subscriptionname: null,
        Customermobile: "",
        paymenttype: null,
        price: null,

        description: "",
      },
      paymentTypes: [
        {
          id: 1,
          name: 'زرین پال'
        },
        {
          id: 2,
          name: 'کارت به کارت'
        },
        {
          id: 3,
          name: 'نقدی'
        },
      ],
      prices: [],
      locations: [],

    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست مشترکین',
        disabled: false,
        href: '/panel/subscriptions/list',
      },
      {
        text: 'ویرایش مشترکین',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig) || {};
      this.prices = appConfig.subscribstring.map(item => item.Subscriptionname)
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })

    });

  },

  methods: {
    async store() {
      this.subscription.Loading = true;
      try {
        await store({
          ...this.subscription,
          Namesofneighborhoods: this.subscription.Namesofneighborhoods.map(item=>item.id).join('-'),
          price: this.subscription.price
              ? removeCommas(this.subscription.price)
              : null,
        });
        this.$toast.success('مشترک با موفقیت اضافه شد.');
        await this.$router.replace('/panel/subscriptions/list')
      } catch (e) {
        console.log(e)
      }
      this.subscription.Loading = false;
    },
  },

  watch: {
    'subscription.price'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.subscription.price = result);
    },

    'subscription.Managermobile'(value) {
      this.subscription.Customermobile = value
    }
  }


}
</script>
